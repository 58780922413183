import { useEvent } from 'hooks'
import { debounce } from 'utils'
import { selectors } from './selector'

const DELAY = 600

export const Submitter = (() => {
	const handleSubmit = (form: HTMLFormElement) => {
		const submit = selectors.getHiddenSubmit(form)
		if (!submit) return
		submit.click()
	}

	const handleEvents = (scope: HTMLElement) => {
		const form = scope.closest('form')
		if (!form) return

		if (scope.tagName.toLowerCase() === 'input') {
			const input = useEvent(scope, 'input')
			input.register(
				debounce(() => {
					handleSubmit(form)
				}, DELAY),
			)
		} else {
			const change = useEvent(scope, 'change')
			change.register(
				debounce(() => {
					handleSubmit(form)
				}, DELAY),
			)
		}
	}

	const initHandlers = () => {
		const handlers = selectors.getAllHandlers()

		for (let i = 0, { length } = handlers; i < length; i++) {
			const handler = handlers[i]
			handleEvents(handler)
		}
	}

	const init = () => {
		initHandlers()
	}

	return {
		init,
	}
})()
