import { wrap, Tooltip, SVGRenderer } from 'highcharts'

export const client = () => {
	wrap(Tooltip.prototype, 'hide', function(defaultCallback) {
		if (
			this.chart.userOptions.chart.type !== 'solidgauge' &&
			!(this.chart.userOptions.chart.type === 'column' && this.chart.userOptions.chart.polar === true)
		) {
			defaultCallback.apply(this, Array.prototype.slice.call(arguments, 1))
		}
	})
	SVGRenderer.prototype.symbols['c-rect'] = function(x, y, w, h) {
		return ['M', x, y + h / 2, 'L', x + w, y + h / 2]
	}
}
