import { useState, useEffect } from 'react'

export const useDebounce = (value: any, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [delay, value])

	return debouncedValue
}
