import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getInputs = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: `.${selector.input}` },
		true,
	)

const getActions = (scope?: HTMLElement) =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: selector.action, scope },
		true,
	)

const getInputFrom = (scope: HTMLElement) =>
	createSelector<HTMLInputElement>({ selector: `.${selector.inputFrom}`, scope })

const getInputTo = (scope: HTMLElement) => createSelector<HTMLInputElement>({ selector: `.${selector.inputTo}`, scope })

export const selectors = {
	getInputs,
	getActions,
	getInputFrom,
	getInputTo,
}
