import React, { useEffect, useState } from 'react'

import { useDebouncedFulltext } from 'react-hooks'
import { makeRequest } from 'services'

import FulltextFilterSelected from './FulltextFilterSelected'
import FulltextFilterInput from './FulltextFilterInput'
import FulltextFilterList from './FulltextFilterList'

import './FulltextFilter.less'

const SEARCH_MIN_CHARS = 3

const classes = {
	filter: 'FulltextFilter',
	more: 'FulltextFilter__more',
}

type Props = {
	endpoint: string
	ident: string
	initialState: string
}
type Data = {
	items: string[]
	total: number
}

const makeFormData = (value: string) => {
	const formData = new window.FormData()
	formData.set('search', value)

	return formData
}

export const FulltextFilter = ({ endpoint, ident, initialState }: Props) => {
	const [debouncedFulltext, fulltext, handleFulltextChange] = useDebouncedFulltext()
	const [loading, setLoading] = useState<boolean>(false)
	const [items, setItems] = useState<string[]>([])
	const [total, setTotal] = useState<number>(0)

	useEffect(() => {
		if (debouncedFulltext.length < SEARCH_MIN_CHARS || endpoint.length === 0) return
		setLoading(true)
		setTotal(0)

		makeRequest<Data>({ method: 'POST', url: endpoint, data: makeFormData(debouncedFulltext) })
			.then(payload => {
				const { data } = payload
				setLoading(false)

				if (!data) return
				if (data.items) setItems(data.items)
				if (data.total) setTotal(data.total)
			})
			.catch(() => {
				setLoading(false)
			})
	}, [debouncedFulltext])

	return (
		<div className={classes.filter}>
			<FulltextFilterSelected ident={ident} initialState={initialState} />
			<FulltextFilterInput fulltext={fulltext} handleFulltextChange={handleFulltextChange} />
			<FulltextFilterList data={items} loading={loading} ident={ident} />
			{total > 0 && <div className={classes.more}>+{total} more items</div>}
		</div>
	)
}
