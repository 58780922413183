import { Accordion, Overlay, Search, Table } from 'component'

export const AlertDashboard = (() => {
	const init = (isAjax?: boolean) => {
		Accordion.init()
		Overlay.init()
		Search.init()
		Table.init(isAjax)
	}

	return {
		init,
	}
})()
