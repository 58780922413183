import React from 'react'

import Loader from './Loader'
import FulltextFilterAction from './FulltextFilterAction'

import './FulltextFilterList.less'

type Props = {
	data: string[]
	ident: string
	loading?: boolean
}

const classes = {
	list: 'FulltextFilterList',
	item: 'FulltextFilterList__item',
}

const makeDataStructureFallback = (data: string[] | object): string[] => {
	if (typeof data === 'object') {
		return Object.values(data).filter(item => item.length !== 0)
	}
	return (data as string[]).filter(item => item.length !== 0)
}

const FulltextFilterList = ({ data, ident, loading }: Props) => {
	if (loading) return <Loader />
	if (!data) return null
	const d = makeDataStructureFallback(data)

	return (
		<ul className={classes.list}>
			{d.map((value, key) => (
				<li className={classes.item} key={key}>
					<FulltextFilterAction ident={ident} value={value} />
				</li>
			))}
		</ul>
	)
}

export default React.memo(FulltextFilterList)
