import { reducer as r } from 'ts-action'
import { on } from 'ts-action-immer'

import { filterActions } from 'store/filter'

export type State = {
	filters: {
		[key: string]: {
			active: string[]
		}
	}
}

const initialState: State = {
	filters: {},
}

export const reducer = r(
	initialState,
	on(filterActions.setInitialFilter, (state, { payload: { filter, value } }) => {
		if (!state.filters[filter]) {
			state.filters[filter] = { active: [] }
		}
		state.filters[filter].active = value
	}),
	on(filterActions.addActiveFilter, (state, { payload: { filter, value } }) => {
		if (!state.filters[filter]) {
			state.filters[filter] = { active: [] }
		}
		state.filters[filter].active.push(value)
	}),
	on(filterActions.removeActiveFilter, (state, { payload: { filter, value } }) => {
		state.filters[filter].active = state.filters[filter].active.filter(item => item !== value)
	}),
)
