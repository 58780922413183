import { AbstractResizer, Deprecated } from 'abstractModule'
import {
	AlertDashboard,
	AlertDetail,
	AlertAppeal,
	BlockHelps,
	Creator,
	Charts,
	CrossTableIndex,
	Drawer,
	Figures,
	FileManager,
	Filter,
	Header,
	Menu,
	Resizer,
	Tracking,
	FulltextFilter,
} from 'modules'

export const Modules = (() => {
	return {
		__deprecated: Deprecated,
		abstractResizer: AbstractResizer,
		alertDashboard: AlertDashboard,
		alertDetail: AlertDetail,
		alertAppeal: AlertAppeal,
		blockHelps: BlockHelps,
		creator: Creator,
		crossTable: CrossTableIndex,
		charts: Charts,
		drawer: Drawer,
		figures: Figures,
		fileManager: FileManager,
		filter: Filter,
		header: Header,
		menu: Menu,
		resizer: Resizer,
		tracking: Tracking,
		fulltext: FulltextFilter,
	}
})()
