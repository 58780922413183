import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import M from 'materialize-css'

type Config = {
	afterRequest?: () => void
} & AxiosRequestConfig

export const makeRequest = <R>({ afterRequest, ...rest }: Config) =>
	new Promise<AxiosResponse<R>>((resolve, reject) => {
		axios
			.request<R>(rest)
			.then(payload => {
				resolve(payload)
			})
			.catch(err => {
				M.toast({ html: err, classes: 'bg-error' })
				reject(err)
			})
			.finally(afterRequest)
	})
