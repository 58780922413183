import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns'

// WEEK
export const getToday = () => {
	return new Date()
}

export const getYesterday = () => {
	return new Date(new Date().setDate(new Date().getDate() - 1))
}

// WEEK
export const getFirstDayOfThisWeek = () => {
	return startOfWeek(new Date())
}

export const getLastDayOfThisWeek = () => {
	return endOfWeek(new Date())
}

export const getFirstDayOfLastWeek = () => {
	return startOfWeek(new Date().setDate(new Date().getDate() - 7))
}

export const getLastDayOfLastWeek = () => {
	return endOfWeek(new Date().setDate(new Date().getDate() - 7))
}

// MONTH
export const getFirstDayOfThisMonth = () => {
	return startOfMonth(new Date())
}

export const getLastDayOfThisMonth = () => {
	return endOfMonth(new Date())
}

export const getFirstDayOfLastMonth = () => {
	return startOfMonth(new Date().setMonth(new Date().getMonth() - 1))
}

export const getLastDayOfLastMonth = () => {
	return endOfMonth(new Date().setMonth(new Date().getMonth() - 1))
}

// YEAR
export const getFirstDayOfThisYear = () => {
	return startOfYear(new Date())
}

export const getLastDayOfThisYear = () => {
	return endOfYear(new Date())
}

export const getFirstDayOfLastYear = () => {
	return startOfYear(new Date().setFullYear(new Date().getFullYear() - 1))
}

export const getLastDayOfLastYear = () => {
	return endOfYear(new Date().setFullYear(new Date().getFullYear() - 1))
}

// LOCALIZATION
export const getWeekdays = (lang = 'en') =>
	Array.from({ length: 7 }, (_, i) => {
		return new Date(new Date().getFullYear(), 0, i - 2).toLocaleDateString(lang, { weekday: 'long' })
	})

export const getWeekdaysShorts = (lang = 'en') =>
	Array.from({ length: 7 }, (_, i) => {
		return new Date(new Date().getFullYear(), 0, i - 2).toLocaleDateString(lang, { weekday: 'short' })
	})

export const getWeekdaysMin = (lang = 'en') =>
	Array.from({ length: 7 }, (_, i) => {
		return new Date(new Date().getFullYear(), 0, i - 2).toLocaleDateString(lang, { weekday: 'narrow' })
	})

export const getMonthsShorts = (lang = 'en') =>
	Array.from({ length: 12 }, (_, i) => {
		return new Date(new Date().getFullYear(), i + 1, 0).toLocaleDateString(lang, { month: 'short' })
	})

export const getMonths = (lang = 'en') =>
	Array.from({ length: 12 }, (_, i) => {
		return new Date(new Date().getFullYear(), i + 1, 0).toLocaleDateString(lang, { month: 'long' })
	})
