import { createSelector } from 'selectors'

import { constant } from './constant'

const { selector } = constant

const getAllHandlers = () =>
	createSelector<NodeListOf<HTMLElement>>(
		{ selector: `.${selector.handler}` },
		true,
	)

const getHiddenSubmit = (scope: HTMLElement) =>
	createSelector<HTMLElement>({ selector: 'button[type="submit"]', scope })

export const selectors = {
	getAllHandlers,
	getHiddenSubmit,
}
