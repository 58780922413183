import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { filterActions, filterSelectors } from 'store/filter'
import { Filter } from 'modules'

import FulltextFilterSelectedRemove from './FulltextFilterSelectedRemove'

import './FulltextFilterSelected.less'

type Props = {
	ident: string
	initialState: string
}

const FulltextFilterSelected = ({ ident, initialState }: Props) => {
	const dispatch = useDispatch()
	const activeFilters = useSelector(filterSelectors.getActiveFiltersByFilter(ident))

	useEffect(() => {
		const filter = Filter

		if (!filter || !activeFilters) return
		filter.handleChangeFilter(ident, activeFilters.join('~'))
	}, [activeFilters])

	useEffect(() => {
		const value = initialState.length > 0 ? initialState.split('~') : []
		dispatch(filterActions.setInitialFilter({ filter: ident, value }))
	}, [dispatch])

	if (!activeFilters || activeFilters.length === 0) return null
	return (
		<ul className="FulltextFilterSelected">
			{activeFilters &&
				activeFilters.map((title, key) => (
					<li key={key} className="FulltextFilterSelected__item">
						{title}
						<FulltextFilterSelectedRemove ident={ident} value={title} />
					</li>
				))}
		</ul>
	)
}

export default React.memo(FulltextFilterSelected)
