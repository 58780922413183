import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { combinedReducer } from './combinedReducer'

const middleware = [thunk]

export const makeStore = () =>
	createStore(
		combinedReducer,
		process.env.NODE_ENV === 'production'
			? applyMiddleware(...middleware)
			: composeWithDevTools(applyMiddleware(...middleware)),
	)
