import React from 'react'
import { useDispatch } from 'react-redux'

import { filterActions } from 'store/filter'

import './FulltextFilterAction.less'

type Props = {
	value: string
	ident: string
}

const FulltextFilterAction = ({ value, ident }: Props) => {
	const dispatch = useDispatch()

	const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		dispatch(filterActions.addActiveFilter({ filter: ident, value }))
	}

	return (
		<button type="button" onClick={handleSelectItem} className="FulltextFilterAction">
			{value}
		</button>
	)
}

export default React.memo(FulltextFilterAction)
