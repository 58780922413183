export const modifier = {
	initialized: 'is-initialized',
	active: 'is-active',
	rotate: 'is-rotate',
	hidden: 'is-hidden',
	hover: 'is-hover',
	desktopHidden: 'is-desktop-hidden',
	scroll: 'is-scrolled',
	border: 'is-bordered',
	filtered: 'is-filtered',
	row: 'is-row',
	highlighted: 'is-highlighted',
	editable: 'is-editable',
	disabled: 'is-disabled',
	open: 'is-open',
}
