import $ from 'jquery'

import { GlobalService } from '@/services'
import { Global } from 'global'
import { useCleanEvents } from 'hooks'

export const Events = () => {
	const onAjaxComplete = (complete: (payload: any) => void) => {
		document.addEventListener('DOMContentLoaded', () => {
			$.nette.ext({
				complete: (payload: any) => {
					useCleanEvents()
					GlobalService.registerAppConfig()
					Global.init({ isAjax: true })
					complete(payload)
				},
			})
		})
	}

	const onBeforeAjaxConfirm = (before: (xhr: any, settings: any) => void) => {
		document.addEventListener('DOMContentLoaded', () => {
			$.nette.ext('confirm', {
				before,
			})
		})
	}

	const onDOMLoad = (listener: () => void) => {
		document.addEventListener('DOMContentLoaded', () => {
			GlobalService.registerAppConfig()
			Global.init({ isAjax: false })
			listener()
		})
	}

	const onLoad = (listener: () => void) => {
		document.addEventListener('load', listener)
	}

	const onNetteExtLoad = (listener: () => void) => {
		document.addEventListener('DOMContentLoaded', () => {
			$.nette.ext('after-load', {
				load: listener,
			})
		})
	}

	return {
		onAjaxComplete,
		onBeforeAjaxConfirm,
		onDOMLoad,
		onLoad,
		onNetteExtLoad,
	}
}
