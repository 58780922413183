import M from 'materialize-css'
import { format } from 'date-fns'

import { GlobalService } from '@/services'
import { Filter } from '@/modules'
import { useEvent, useState } from 'hooks'
import { dateHelper } from 'helper'

import { constant } from './constant'
import { selectors } from './selector'
import { filterSelectors } from 'selectors'
import { module } from 'constant'

type PickDate = {
	from: Date
	to: Date
}
type SelectDate = {
	from: string
	to: string
}

type State = {
	isAjax?: boolean
	onSelect?: (date: string) => void
	onAction?: (date: SelectDate) => void
	selected?: {
		from: Date
		to: Date
	}
}

const DATE_FORMAT = 'yyyy-mm-dd'
const SET_DATE_FORMAT = 'yyyy-MM-dd'

const getDateByAction = (action: string): PickDate | null => {
	switch (action) {
		case 'this-year': {
			return { from: dateHelper.getFirstDayOfThisYear(), to: dateHelper.getLastDayOfThisYear() }
		}
		case 'last-year': {
			return { from: dateHelper.getFirstDayOfLastYear(), to: dateHelper.getLastDayOfLastYear() }
		}
		case 'this-month': {
			return { from: dateHelper.getFirstDayOfThisMonth(), to: dateHelper.getLastDayOfThisMonth() }
		}
		case 'last-month': {
			return { from: dateHelper.getFirstDayOfLastMonth(), to: dateHelper.getLastDayOfLastMonth() }
		}
		case 'this-week': {
			return { from: dateHelper.getFirstDayOfThisWeek(), to: dateHelper.getLastDayOfThisWeek() }
		}
		case 'last-week': {
			return { from: dateHelper.getFirstDayOfLastWeek(), to: dateHelper.getLastDayOfLastWeek() }
		}
		case 'today': {
			return { from: dateHelper.getToday(), to: dateHelper.getToday() }
		}
		case 'yesterday': {
			return { from: dateHelper.getYesterday(), to: dateHelper.getYesterday() }
		}
		case 'all': {
			return null
		}
		default: {
			return null
		}
	}
}

export const Datepicker = (() => {
	const { state, setState } = useState<State>({})
	const datepickers: M.Datepicker[] = []

	const getInstanceByEl = (el: HTMLElement) => {
		const index = datepickers.findIndex(item => item.el === el)
		if (index < 0) return null

		return datepickers[index]
	}

	const handleAction = (scope: HTMLElement) => {
		const click = useEvent<Event>(scope, 'click')
		const action = scope.getAttribute(constant.selector.action)
		const parent = scope.closest<HTMLElement>(`.${constant.selector.target}`)
		if (!action || !parent) return

		const filterId = parent.getAttribute(constant.selector.filterId)
		const from = selectors.getInputFrom(parent)
		const to = selectors.getInputTo(parent)
		if (!from || !to) return

		const instanceFrom = getInstanceByEl(from)
		const instanceTo = getInstanceByEl(to)
		if (!instanceFrom || !instanceTo) return

		click.register(({ e }) => {
			e.preventDefault()

			const d = getDateByAction(action)
			if (!d) {
				from.value = ''
				to.value = ''
				if (state.onAction) state.onAction({ from: '', to: '' })
				return
			}

			instanceFrom.setDate(d.from)
			instanceTo.setDate(d.to)
			from.value = format(d.from, SET_DATE_FORMAT)
			to.value = format(d.to, SET_DATE_FORMAT)
			const newValue = { from: format(d.from, SET_DATE_FORMAT), to: format(d.to, SET_DATE_FORMAT) }
			const id = filterId || ''

			const instanceFromEl = instanceFrom.el as HTMLInputElement
			const instanceToEl = instanceTo.el as HTMLInputElement
			instanceFromEl.setAttribute(constant.selector.fromValue, newValue.from)
			instanceToEl.setAttribute(constant.selector.toValue, newValue.to)

			GlobalService.setFilter(id, newValue)
			Filter.setFilterSelectionValue(id, getFormattedValue(newValue))
			Filter.setFilterInputValue(id)

			// TODO: Deprecated -> refactor!!
			Filter.dispatchChange(
				Array.from(filterSelectors.getFilterInputs(id)).filter(
					input => input.getAttribute(module.filter.selector.filterValueKey) === 'from',
				)[0],
			)

			// TODO: Deprecated -> refactor!!
			setTimeout(() => {
				Filter.dispatchChange(
					Array.from(filterSelectors.getFilterInputs(id)).filter(
						input => input.getAttribute(module.filter.selector.filterValueKey) === 'to',
					)[0],
				)
			}, 500)

			setState({ selected: { from: d.from, to: d.to } })

			if (state.onAction) {
				state.onAction(newValue)
			}
		})
	}

	const getFormattedValue = ({ from, to }: { from: string; to: string }) => {
		if (!from && !to) {
			return ''
		}
		if (!!from && !to) {
			return from
		}
		if (!from && !!to) {
			return to
		}
		return `${from} - ${to}`
	}

	const initActions = () => {
		const actions = selectors.getActions()
		for (let i = 0, { length } = actions; i < length; i++) {
			handleAction(actions[i])
		}
	}

	const initInputs = () => {
		const inputs = selectors.getInputs()
		if (!inputs) return

		for (let i = 0, { length } = inputs; i < length; i++) {
			const input = inputs[i] as HTMLInputElement
			const fromValue = input.getAttribute(constant.selector.fromValue)
			const toValue = input.getAttribute(constant.selector.toValue)
			const isFrom = !!fromValue && fromValue !== '0'
			const isTo = !!toValue && toValue !== '0'
			const parent = input.closest<HTMLElement>(`.${constant.selector.target}`)
			if (!parent) return

			const filterId = parent.getAttribute(constant.selector.filterId)

			if (isFrom) {
				const f = format(new Date(fromValue || ''), SET_DATE_FORMAT)
				input.value = f
				input.setAttribute(constant.selector.fromValue, f)
			}

			if (isTo) {
				const f = format(new Date(toValue || ''), SET_DATE_FORMAT)
				input.value = f
				input.setAttribute(constant.selector.toValue, f)
			}

			const instance = M.Datepicker.init(input, {
				i18n: {
					weekdays: dateHelper.getWeekdays(GlobalService.getLang()),
					weekdaysShort: dateHelper.getWeekdaysShorts(GlobalService.getLang()),
					weekdaysAbbrev: dateHelper.getWeekdaysMin(GlobalService.getLang()),
					months: dateHelper.getMonths(GlobalService.getLang()),
					monthsShort: dateHelper.getMonthsShorts(GlobalService.getLang()),
				},
				container: document.body,
				format: DATE_FORMAT,
				onSelect: value => {
					const id = filterId || ''
					const isSelectedFrom = !!instance.el.getAttribute(constant.selector.fromValue)

					instance.el.setAttribute(
						isSelectedFrom ? constant.selector.fromValue : constant.selector.toValue,
						format(value, SET_DATE_FORMAT),
					)

					GlobalService.setFilter(
						id,
						isSelectedFrom ? { from: format(value, SET_DATE_FORMAT) } : { to: format(value, SET_DATE_FORMAT) },
					)
					if (state.onSelect) {
						state.onSelect(format(value, SET_DATE_FORMAT))
					}

					const filterValue = GlobalService.getFilter(id)
					if (!filterValue) return

					Filter.setFilterSelectionValue(id, getFormattedValue(filterValue))
					Filter.setFilterInputValue(id)
					setState({ selected: { ...state.selected, [isSelectedFrom ? 'from' : 'to']: value } })
				},
				onClose: () => {
					const id = filterId || ''
					const isSelectedFrom = !!instance.el.getAttribute(constant.selector.fromValue)
					const changeInput = Array.from(filterSelectors.getFilterInputs(id)).filter(
						filterInput =>
							filterInput.getAttribute(module.filter.selector.filterValueKey) === (isSelectedFrom ? 'from' : 'to'),
					)[0]
					if (state.selected) {
						if (isSelectedFrom) {
							changeInput.value = format(state.selected.from, SET_DATE_FORMAT)
						} else {
							changeInput.value = format(state.selected.to, SET_DATE_FORMAT)
						}
					}
					Filter.dispatchChange(changeInput)
				},
			})

			if (isFrom && state.selected) {
				instance.setDate(state.selected.from || new Date(fromValue!))
			}
			if (isTo && state.selected) {
				instance.setDate(state.selected.to || new Date(toValue!))
			}
			datepickers.push(instance)
		}
	}

	const init = (props?: State) => {
		setState({ ...props })
		initInputs()
		initActions()
	}

	return {
		init,
	}
})()
