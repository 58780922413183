import { createSelector } from 'reselect'

import { State } from 'store/types'

export const getFilters = (state: State) => state.filter.filters

export const getActiveFiltersByFilter = (filter: string) =>
	createSelector(
		[getFilters],
		filters => {
			if (!filters) return null
			const f = filters[filter]

			if (!f) return null
			return f.active
		},
	)
