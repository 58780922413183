import { modifier, module } from 'constant'
import { filterSelectors, getFilterSelectionName, getFilterSelectors } from 'selectors'
import { useAnimation, useEvent, useState } from 'hooks'
import { cn } from 'utils'
import { GlobalService } from 'services'
import { ContainerComponent, Loader } from 'component'

type State = {
	filterHandlers?: NodeListOf<HTMLElement>
	filterResets?: NodeListOf<HTMLElement>
	activeFilters?: HTMLElement
	filtered: boolean
} & ModuleProps

type ModuleProps = {
	onReset?: (() => void)[]
	isAjax?: boolean
}

export const Filter = (() => {
	const { state, setState } = useState<State>({
		filtered: false,
		pageYScrolled: false,
	})

	const dispatchResetCallbacks = () => {
		const { onReset } = state
		if (!onReset) return

		const resetCallbacksLength = onReset.length

		for (let i = 0; i < resetCallbacksLength; i++) {
			const cb = onReset[i]

			if (!cb) continue
			cb()
		}
	}

	const handleChangeFilter = (ident: string, value: string) => {
		const filterInput = getFilterSelectionName(ident) as HTMLInputElement

		if (!filterInput) return
		filterInput.value = value
		const event = new window.UIEvent('change', {
			view: window,
			bubbles: true,
			cancelable: true,
		})
		filterInput.dispatchEvent(event)
	}

	const submitFilters = () => {
		const { filterHandlers } = state
		// TODO: Refactor
		const submitter = document.querySelector<HTMLInputElement>('.filter-submit')

		if (!submitter) return
		submitter.click()

		Loader.create(ContainerComponent.getContainer('loader'), { absolute: true })

		if (!filterHandlers) return
		const filterHandlersLength = filterHandlers.length

		if (filterHandlersLength === 0) return

		for (let i = 0; i < filterHandlersLength; i++) {
			const handler = filterHandlers[i]
			cn.addClass(handler, modifier.filtered)
		}

		setState({ filtered: true })
	}

	const handleResetFilters = () => {
		// TODO: Refactor
		const resetter = document.querySelector<HTMLInputElement>('input[name="_reset"]')

		if (!resetter) return
		resetter.click()

		dispatchResetCallbacks()
	}

	const setResetEvents = (reset: HTMLElement) => {
		const { filterHandlers } = state
		const click = useEvent(reset, 'click')

		click.register(() => {
			handleResetFilters()

			if (!filterHandlers) return
			const filterHandlersLength = filterHandlers.length

			if (filterHandlersLength === 0) return

			for (let i = 0; i < filterHandlersLength; i++) {
				const handler = filterHandlers[i]
				cn.removeClass(handler, modifier.filtered)
			}

			setState({ filtered: false })
		})
	}

	const handleActiveFiltersScrolled = (once: boolean) => {
		const { activeFilters, pageYScrolled } = state

		if (!activeFilters) return
		const topScrollPosition = window.pageYOffset || document.documentElement.scrollTop

		if (topScrollPosition > 1) {
			if (!once && pageYScrolled) return

			cn.addClass(activeFilters, modifier.scroll)
			useAnimation({
				el: activeFilters,
				from: { transform: `translateY(0px)` },
				to: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
				duration: 200,
			})
			setState({ pageYScrolled: true })
		} else {
			if (!pageYScrolled) return
			cn.removeClass(activeFilters, modifier.scroll)
			useAnimation({
				el: activeFilters,
				from: { transform: `translateY(-${module.header.size.scrolledDiff}px)` },
				to: { transform: `translateY(0px)` },
				duration: 200,
			})
			setState({ pageYScrolled: false })
		}
	}

	const getFilterValue = (id: string) => {
		return GlobalService.getFilter(id)
	}

	const setFilterSelectionValue = (id: string, value: string) => {
		const selection = filterSelectors.getFilterSelection(id)
		if (!selection) return

		const span = filterSelectors.getFilterSelectionSpan(selection)
		if (!span) return

		span.textContent = value
	}

	const setFilterInputValue = (id: string) => {
		const inputs = filterSelectors.getFilterInputs(id)
		if (!inputs) return

		const value = getFilterValue(id)
		if (!value) return

		for (let i = 0, { length } = inputs; i < length; i++) {
			const input = inputs[i]
			const key = input.getAttribute(module.filter.selector.filterValueKey)
			if (!key) return
			if (!value[key]) return

			input.value = value[key]
		}
	}

	const dispatchChange = (input: HTMLInputElement) => {
		const event = new window.Event('change')
		input.dispatchEvent(event)
	}

	const initReset = () => {
		const { filterResets } = state

		if (!filterResets) return
		const filterResetsLength = filterResets.length

		if (filterResetsLength === 0) return
		for (let i = 0; i < filterResetsLength; i++) {
			const reset = filterResets[i]
			setResetEvents(reset)
		}
	}

	const initWindowEvents = () => {
		const scroll = useEvent(window, 'scroll')
		scroll.register(() => {
			handleActiveFiltersScrolled(false)
		})

		handleActiveFiltersScrolled(true)
	}

	const init = (props?: ModuleProps) => {
		if (props) setState(props)
		setState({ ...getFilterSelectors() })
		initReset()
		initWindowEvents()
	}

	return {
		init,
		initWindowEvents,
		submitFilters,
		handleChangeFilter,
		setFilterSelectionValue,
		setFilterInputValue,
		dispatchChange,
	}
})()
