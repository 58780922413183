import { createSelector } from 'selectors'

import { constant } from './container.constant'

const { selector } = constant

const getContainer = (value: string) => {
	const containers = createSelector<NodeListOf<HTMLElement>>({ selector: selector.container, value }, true)

	if (containers.length > 1) {
		console.warn('Non-unique CONTAINER: value')
	}

	return containers[0]
}

export const containerSelectors = {
	getContainer,
}
