import React from 'react'
import { useDispatch } from 'react-redux'

import { filterActions } from 'store/filter'

type Props = {
	ident: string
	value: string
}

const FulltextFilterSelectedRemove = ({ ident, value }: Props) => {
	const dispatch = useDispatch()

	const handleRemoveActiveFilter = () => {
		dispatch(filterActions.removeActiveFilter({ filter: ident, value }))
	}

	return (
		<button onClick={handleRemoveActiveFilter} className="FulltextFilterSelected__remove" type="button">
			<i className="FulltextFilterSelected__remove-shape fas fa-times" />
		</button>
	)
}

export default React.memo(FulltextFilterSelectedRemove)
