export const filter = {
	selector: {
		handler: '.js-filter-handler',
		reset: '.js-filter-reset',
		active: '.js-active-filters',
		filterValue: 'data-name',
		filterSelection: 'data-filter-selection',
		filterInput: 'data-m-input-filter-id',
		filterValueKey: 'data-m-input-filter-value-key',
	},
}
