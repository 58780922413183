import { CrossTable } from './crossTable'
import { CrosstableEditor } from './editor'
import { CrossTablePopups } from './popups'
import { CrossTableFilter } from './filter'
import { CrossTableQuestions } from './questions'
import { CrossTableSettings } from './settings'

// TODO: Move to abstract modules
export const CrossTableIndex = (() => {
	const crossTable = CrossTable()
	const crossTableEditor = CrosstableEditor()
	const crossTablePopups = CrossTablePopups()
	const crossTableFilter = CrossTableFilter()
	const crossTableQuestions = CrossTableQuestions()
	const crossTableSettings = CrossTableSettings()
	const init = (isAjax?: boolean) => {
		crossTable.init()
		crossTableEditor.init()
		crossTablePopups.init(isAjax)
		crossTableFilter.init()
		crossTableQuestions.init()
		crossTableSettings.init()
	}

	return {
		init,
	}
})()
