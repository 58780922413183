import M from 'materialize-css'
import { cn } from 'utils'
import { useEvent, useOutsideClick } from 'hooks'
import { modifier } from 'constant'

const WRAPPER_CLASS = 'select-wrapper'

const Select = () => {
	const init = () => {
		const targets = document.querySelectorAll('select')

		for (let i = 0, { length } = targets; i < length; i++) {
			const item = targets[i]
			const { parentNode } = item
			if (parentNode && cn.hasClass(parentNode as HTMLElement, WRAPPER_CLASS)) continue
			const select = M.FormSelect.init(item, {
				dropdownOptions: {
					container: document.body,
				},
			})
			const scope = select.dropdownOptions
			const click = useEvent(select.input, 'click')
			click.register(() => {
				if (!cn.hasClass(scope, modifier.open)) {
					cn.addClass(scope, modifier.open)
				}
			})
			useOutsideClick(select.input, () => {
				cn.removeClass(scope, modifier.open)
			})
		}
	}

	return {
		init,
	}
}

export default Select
