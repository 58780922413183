export const constant = {
	selector: {
		target: 'js-m-datepicker',
		input: 'js-m-datepicker-input',
		inputFrom: 'js-m-datepicker-input-from',
		inputTo: 'js-m-datepicker-input-to',
		action: 'data-m-datepicker-action',
		fromValue: 'data-m-datepicker-from',
		toValue: 'data-m-datepicker-to',
		filterId: 'data-m-datepicker-filter-id',
	},
}
