import React, { useState } from 'react'

import { useDebounce } from 'react-hooks'

export const useDebouncedFulltext = (): [string, string, (e: React.ChangeEvent<HTMLInputElement>) => void] => {
	const [fulltext, setFulltext] = useState<string>('')
	const debouncedFulltext = useDebounce(fulltext)

	const handleChangeFulltext = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFulltext(e.target.value)
	}

	return [debouncedFulltext, fulltext, handleChangeFulltext]
}
