import { Activator, Submitter, Datepicker } from 'component'
import Modal from './modal'
import Select from './select'

type Props = {
	isAjax: boolean
}

const Global = (() => {
	const init = (props: Props) => {
		const modal = Modal()
		const select = Select()

		Activator.init()
		Submitter.init()
		Datepicker.init({ isAjax: props.isAjax })
		modal.init()
		select.init()
	}

	return {
		init,
	}
})()

export default Global
