import interact from 'interactjs'
import Sortable, { SortableEvent } from 'sortablejs'

import { array, cn } from 'utils'
import { module, modifier } from 'constant'
import { useAnimation, useEvent, useState } from 'hooks'
import { resizerSelectors } from 'selectors'
import { Loader } from 'component'

export type Sizes = {
	[key: string]: {
		w?: number
		h?: number
		parent?: string
	}
}

type InitProps = {
	onAdd?: (e: SortableEvent) => void
	onInit?: () => void
	onResizeActiveChange?: (active: boolean) => void
	onResizeComplete?: () => void
	onSortChange?: (sortableEvent: SortableEvent) => void
	onAutoHeight?: (newSizes: Sizes) => void
	onReinit?: () => void
	onActivate?: () => void
	onDeactivate?: () => void
}

const {
	resizer: { selector, style },
} = module

const AUTO_ONRESIZE_HEIGHT_INDEX = 5
const AUTO_ANIMATION_TIME = 300
const ANIMATION_TIME = 500
const GRID_COL_HEIGHT = 80
const SCROLL_LAST_ROW_THRESHOLD = 100
const GRID_COLS = 12
const HEIGHT_GRID_COLS = 40
const CLASS_PANEL_RIGHT = 'blocks-resizer__panel-right'
const CLASS_PANEL_BOTTOM = 'blocks-resizer__panel-bottom'
const CLASS_LINES_WRAP = 'blocks-resizer__lines-wrap'
const CLASS_LINES = 'blocks-resizer__lines'
const CLASS_LINE = 'blocks-resizer__line'
const CLASS_ROW_LINE = 'blocks-resizer__row-line'
const SELECTOR_CLASS_PANEL_RIGHT = cn.removeDot(selector.rightPanel)
const SELECTOR_CLASS_PANEL_BOTTOM = cn.removeDot(selector.bottomPanel)

const makeWidths = () => {
	const widths: number[] = []
	for (let i = 1; i <= GRID_COLS; i++) {
		widths.push((100 / GRID_COLS) * i)
	}
	return widths
}

const makeHeights = () => {
	const heights: number[] = []
	for (let i = 1; i <= HEIGHT_GRID_COLS; i++) {
		heights.push(GRID_COL_HEIGHT * i)
	}
	return heights
}

const makeFilteredSizes = (sizes: Sizes, parentId: string) => {
	const keys = Object.keys(sizes)
	const values = Object.values(sizes)
	const { length } = keys
	const newSizes: Sizes = {}

	for (let i = 0; i < length; i++) {
		const id = keys[i]
		const { w, h, parent } = values[i]
		if (!parent && !parentId) {
			newSizes[id] = { w, h }
		}
		if (parent === parentId) {
			newSizes[id] = { w, h, parent }
		}
	}

	return newSizes
}

const makeParentsSizes = (sizes: Sizes) => {
	const keys = Object.keys(sizes)
	const values = Object.values(sizes)
	const { length } = keys
	const newSizes: Sizes = {}

	for (let i = 0; i < length; i++) {
		const id = keys[i]
		const { w, h, parent } = values[i]
		if (parent) {
			newSizes[id] = { w, h, parent }
		}
	}

	return newSizes
}

const makeParents = (sizes: Sizes) => {
	const keys = Object.keys(sizes)
	const values = Object.values(sizes)
	const { length } = keys
	const parents = []

	for (let i = 0; i < length; i++) {
		const { parent } = values[i]
		if (parent && parents.indexOf(parent) < 0) {
			parents.push(parent)
		}
	}

	return parents
}

const getPercentsNumber = (parentWidth: number, width: number) => {
	return (width / parentWidth) * 100
}

const getPercents = (parentWidth: number, width: number) => {
	return `${getPercentsNumber(parentWidth, width)}%`
}

export const Resize = () => {
	const { state, setState } = useState({
		active: false,
		widths: makeWidths(),
		heights: makeHeights(),
		sizes: {} as Sizes,
		sortables: [] as Sortable[],
		autoHeightsComplete: false,
	})

	const getNearestWidth = (newWidth: number, id: string) => {
		const { widths } = state
		let width = newWidth

		if (width > 100) width = 100

		if (!widths) return width
		const { length } = widths

		if (length === 0) return width
		const wIndex = widths.indexOf(width)

		if (wIndex > -1) {
			setState({ sizes: { ...state.sizes, [id]: { ...state.sizes[id], w: wIndex + 1 } } })
			return width
		}

		const halfDiff = widths[0] / 2
		let r = widths[0]

		for (let i = 0; i < length; i++) {
			const w = widths[i]
			const nextW = widths[i + 1]

			if (w < width && nextW > width) {
				if (w + halfDiff <= width) {
					r = nextW
				}
				if (w + halfDiff > width) {
					r = w
				}
			}
		}

		setState({ sizes: { ...state.sizes, [id]: { ...state.sizes[id], w: widths.indexOf(r) + 1 } } })

		return r
	}

	const getNearestHeight = (height: number, id: string) => {
		const { heights } = state

		if (!heights) return height
		const { length } = heights

		if (length === 0) return height
		const hIndex = heights.indexOf(height)

		if (hIndex > -1) {
			setState({ sizes: { ...state.sizes, [id]: { ...state.sizes[id], h: hIndex + 1 } } })
			return height
		}

		const halfDiff = heights[0] / 2
		let r = heights[0]

		for (let i = 0; i < length; i++) {
			const h = heights[i]
			const nextH = heights[i + 1]

			if (h < height && nextH > height) {
				if (h + halfDiff <= height) {
					r = nextH
				}
				if (h + halfDiff > height) {
					r = h
				}
			}
		}

		setState({ sizes: { ...state.sizes, [id]: { ...state.sizes[id], h: heights.indexOf(r) + 1 } } })

		return r
	}

	const destroyResizerLoading = () => {
		const { wrap } = state
		const loaders = resizerSelectors.getLoaders()

		if (!wrap || !loaders) return

		for (let i = 0, { length } = loaders; i < length; i++) {
			const loader = loaders[i]
			const { parentNode } = loader

			if (!parentNode) continue
			parentNode.removeChild(loader)
		}

		for (let i = 0, { length } = wrap; i < length; i++) {
			cn.removeClass(wrap[i], modifier.hidden)
		}
	}

	const makeLoading = () => {
		const { containers } = state

		if (!containers) return
		const { length } = containers

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const container = containers[i]
			const fragment = document.createDocumentFragment()
			const loading = document.createElement('div')
			cn.addClass(loading, style.loading)
			cn.addClass(loading, cn.removeDot(selector.loading))

			fragment.appendChild(loading)
			container.appendChild(fragment)
			Loader.create(container, { absolute: true })

			cn.addClass(loading, modifier.active)
		}
	}

	const destroyLoading = () => {
		destroyResizerLoading()

		const loadings = resizerSelectors.getLoadings()

		if (!loadings) return
		const { length } = loadings

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const loading = loadings[i] as HTMLElement

			if (!loading) continue
			const { parentNode } = loading

			if (!parentNode) continue
			cn.removeClass(loading, modifier.active)
			setTimeout(() => {
				if (!loading) return
				parentNode.removeChild(loading)
			}, 200)
		}
		Loader.destroyAll()
	}

	const makeItemCover = () => {
		const { items } = state

		if (!items) return
		const { length } = items

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const item = items[i]

			if (!item) continue
			const block = resizerSelectors.getItemBlock(item)

			if (!block) continue
			const fragment = document.createDocumentFragment()
			const cover = document.createElement('div')

			cn.addClass(cover, cn.removeDot(selector.itemCover))
			cn.addClass(cover, style.itemCover)

			fragment.appendChild(cover)
			block.appendChild(fragment)
		}
	}

	const destroyItemCover = () => {
		const { items } = state

		if (!items) return
		const { length } = items

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const item = items[i]

			if (!item) continue
			const cover = resizerSelectors.getItemCover(item)

			if (!cover) continue
			const { parentNode } = cover

			if (!parentNode) return
			parentNode.removeChild(cover)
		}
	}

	const makeLines = () => {
		const fragment = document.createDocumentFragment()
		const linesWrap = document.createElement('div')
		const lines = document.createElement('ul')
		const rowLinesWrap = document.createElement('div')
		const rowLines = document.createElement('ul')

		cn.addClass(linesWrap, CLASS_LINES_WRAP)
		cn.addClass(linesWrap, cn.removeDot(selector.lines))
		cn.addClass(lines, CLASS_LINES)

		cn.addClass(rowLinesWrap, CLASS_LINES_WRAP)
		cn.addClass(rowLinesWrap, cn.removeDot(selector.lines))
		cn.addClass(rowLines, CLASS_LINES)
		cn.addClass(rowLines, modifier.row)

		for (let i = 0; i < GRID_COLS - 1; i++) {
			const line = document.createElement('li')
			cn.addClass(line, CLASS_LINE)
			lines.appendChild(line)
		}

		for (let i = 0; i < HEIGHT_GRID_COLS - 1; i++) {
			const rowLine = document.createElement('li')
			cn.addClass(rowLine, CLASS_ROW_LINE)
			rowLines.appendChild(rowLine)
		}

		linesWrap.appendChild(lines)
		rowLinesWrap.appendChild(rowLines)

		fragment.appendChild(linesWrap)
		fragment.appendChild(rowLinesWrap)

		return fragment
	}

	const destroyLines = () => {
		const lines = resizerSelectors.getLines()

		if (!lines) return
		const { length } = lines

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const item = lines[i]
			const parent = item.parentNode as HTMLElement

			if (!parent) continue
			parent.removeChild(item)
		}
	}

	const handleLines = () => {
		const { containers, active } = state

		if (!containers) return
		const { length } = containers

		if (length === 0) return
		if (!active) {
			destroyLines()
			return
		}
		for (let i = 0; i < length; i++) {
			const container = containers[i]
			container.appendChild(makeLines())
		}
	}

	const makeItemsPanels = (scope: HTMLElement) => {
		const fragment = document.createDocumentFragment()
		const rightPanel = document.createElement('div')
		const bottomPanel = document.createElement('div')

		cn.addClass(rightPanel, CLASS_PANEL_RIGHT)
		cn.addClass(rightPanel, SELECTOR_CLASS_PANEL_RIGHT)

		cn.addClass(bottomPanel, CLASS_PANEL_BOTTOM)
		cn.addClass(bottomPanel, SELECTOR_CLASS_PANEL_BOTTOM)

		fragment.appendChild(rightPanel)
		fragment.appendChild(bottomPanel)

		scope.appendChild(fragment)
	}

	const destroyPanels = () => {
		const rightPanels = resizerSelectors.getRightPanels()
		const bottomPanels = resizerSelectors.getBottomPanels()

		if (!rightPanels || !bottomPanels) return
		const rightPanelsLength = rightPanels.length
		const bottomPanelsLength = bottomPanels.length

		if (rightPanelsLength === 0) return
		for (let i = 0; i < rightPanelsLength; i++) {
			const rightPanel = rightPanels[i]
			const parent = rightPanel.parentNode as HTMLElement

			if (!parent) continue
			parent.removeChild(rightPanel)
		}

		if (bottomPanelsLength === 0) return
		for (let i = 0; i < bottomPanelsLength; i++) {
			const bottomPanel = bottomPanels[i]
			const parent = bottomPanel.parentNode as HTMLElement

			if (!parent) continue
			parent.removeChild(bottomPanel)
		}
	}

	const handleActivateResizeEvents = (el: HTMLElement) => {
		const { register } = useEvent(el, 'click')
		const { onResizeActiveChange, onActivate, onDeactivate } = state

		register(() => {
			if (state.active) {
				destroyResizeEvent()
				destroySortable()
				activateWrapperChildren(true)
				setState({ active: false })
				cn.removeClass(el, modifier.active)
				if (onDeactivate) onDeactivate()
			} else {
				makeItemCover()
				initResizeEvents()
				initSortable()
				activateWrapperChildren(false)
				setState({ active: true })
				cn.addClass(el, modifier.active)
				if (onActivate) onActivate()
			}
			if (onResizeActiveChange) onResizeActiveChange(state.active)

			handleShowItemsPanels()
			handleLines()

			if (!state.active) {
				destroyPanels()
				destroyItemCover()
			}
		})
	}

	const reinitActive = () => {
		const { onResizeActiveChange, onActivate, onReinit } = state

		makeItemCover()
		initResizeEvents()
		destroySortable()

		setState({ active: true })
		if (onReinit) onReinit()
		if (onActivate) onActivate()
		if (onResizeActiveChange) onResizeActiveChange(state.active)

		initSortable()
		destroyPanels()
		handleShowItemsPanels()

		destroyLines()
		handleLines()
	}

	const handleShowItemsPanels = () => {
		const { items, active } = state

		if (!items) return
		const { length } = items

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const item = items[i]

			if (!item) continue
			if (active) {
				makeItemsPanels(item)
			}
		}
	}

	const getFormatedSizesData = (sizes: Sizes, parentId: string) => {
		const filtteredSizes = makeFilteredSizes(sizes, parentId)
		const keys = Object.keys(filtteredSizes)
		const values = Object.values(filtteredSizes)
		const sizesLength = keys.length
		const data: number[][] = []
		const ids: string[][] = []

		if (sizesLength === 0) {
			return {
				data,
				ids,
			}
		}
		let index = 0
		let arrIndex = 0
		let totalW = 0

		while (index < sizesLength) {
			const key = keys[index]
			const { w, h } = values[index]

			if (!w) continue

			totalW += w

			if (totalW <= GRID_COLS) {
				if (!data[arrIndex]) data[arrIndex] = []
				if (!ids[arrIndex]) ids[arrIndex] = []
				data[arrIndex].push(typeof h === 'undefined' ? 1 : h)
				ids[arrIndex].push(key)
			}

			if (totalW > GRID_COLS) {
				totalW = 0
				arrIndex++
				index--
			}

			index++
		}

		return {
			data,
			ids,
		}
	}

	const animateBlocksHeights = (flattenArr: number[], flattenIds: string[]) => {
		const { heights } = state
		const arrLength = flattenArr.length
		const idsLength = flattenIds.length
		const promise = [] as Promise<any>[]

		if (arrLength !== idsLength) return promise
		for (let i = 0; i < idsLength; i++) {
			const itemId = flattenIds[i]
			const newHeight = flattenArr[i]

			if (!itemId) continue
			const item = resizerSelectors.getItemById(itemId)

			if (!item) continue
			const oldHeight = item.getAttribute(selector.height)

			if (oldHeight && parseFloat(oldHeight) === 0) {
				item.setAttribute(selector.autoHeight, String(newHeight))
				promise.push(
					new Promise<any>(resolve => {
						setTimeout(() => {
							useAnimation({
								el: item,
								from: { height: `${item.offsetHeight}px` },
								to: { height: `${heights[newHeight - 1]}px` },
								duration: AUTO_ANIMATION_TIME,
								onComplete: resolve,
							})
						}, 20)
					}),
				)
			}

			if (!oldHeight || (oldHeight && parseFloat(oldHeight) === 0) || newHeight === 0) continue
			promise.push(
				new Promise<any>(resolve => {
					setTimeout(() => {
						const AHIndex = item.getAttribute(selector.autoHeight)
						useAnimation({
							el: item,
							from: { height: `${heights[(AHIndex ? parseFloat(AHIndex) : parseFloat(oldHeight)) - 1]}px` },
							to: { height: `${heights[newHeight - 1]}px` },
							duration: AUTO_ANIMATION_TIME,
							onComplete: () => {
								resolve()
								item.removeAttribute(selector.autoHeight)
							},
						})
					}, 20)
				}),
			)

			item.setAttribute(selector.height, String(newHeight))
		}

		return promise
	}

	const setRowHeights = (id: string, isNewHeightHigher: boolean, parentId: string, oldSizes?: Sizes) => {
		return new Promise(res => {
			const { sizes } = state
			const { data, ids } = getFormatedSizesData(sizes, parentId)

			const minMaxArr = data.map((subArr, key) => {
				return subArr.map((value, subKey) => {
					if (ids[key].indexOf(id) < 0) return value

					const max = Math.max(...subArr)
					const min = Math.min(...subArr.filter(val => val !== 0))

					if (value === 0) {
						const itemId = ids[key][subKey]
						const item = resizerSelectors.getItemById(itemId)

						if (!item) return min
						const itemBlock = resizerSelectors.getItemBlock(item)

						if (!itemBlock) return min
						const content = itemBlock.children[0] as HTMLElement

						if (!content) return min
						const newIndex = Math.ceil(content.offsetHeight / GRID_COL_HEIGHT)

						if (isNewHeightHigher) {
							return max > newIndex ? max : newIndex
						}
						return newIndex > min ? newIndex : min
					}

					if (isNewHeightHigher) {
						return max
					}
					return min
				})
			})

			let idsIndex = 0
			const flattenIds = ids.filter((a, i) => {
				const bool = a.indexOf(id) > -1
				if (bool) {
					idsIndex = i
					return true
				}
				return false
			})[0]
			const flattenArr = minMaxArr[idsIndex]
			const arrLength = flattenArr.length
			const idsLength = flattenIds.length

			if (arrLength !== idsLength) return
			const promise = animateBlocksHeights(flattenArr, flattenIds)

			if (oldSizes) {
				promise.push(autoCorrectHeights(oldSizes, parentId))
			}

			Promise.all(promise).then(() => {
				if (state.onResizeComplete) state.onResizeComplete()
				res()
			})
		})
	}

	const autoCorrectHeights = (oldSizes: Sizes, parentId: string) => {
		return new Promise(res => {
			const { sizes } = state
			const before = getFormatedSizesData(oldSizes, parentId)
			const now = getFormatedSizesData(sizes, parentId)
			const beforeDataLength = before.data.length
			const dataLength = now.data.length
			const correctArr: number[][] = []
			const correctIds: string[][] = []

			if (beforeDataLength !== dataLength) {
				res()
				return
			}
			for (let i = 0; i < dataLength; i++) {
				const beforeArr = before.data[i]
				const arr = now.data[i]
				const ids = now.ids[i]

				if (!array.compareLength(beforeArr, arr)) {
					correctArr.push(arr)
					correctIds.push(ids)
				}
			}

			if (correctArr.length === 0) {
				res()
				return
			}
			const minMaxArr = correctArr.map((subArr, key) => {
				return subArr.map((value, subKey) => {
					const max = Math.max(...subArr)
					const min = Math.min(...subArr.filter(val => val !== 0))

					if (value === 0) {
						const itemId = correctIds[key][subKey]
						const item = resizerSelectors.getItemById(itemId)

						if (!item) return min
						const itemBlock = resizerSelectors.getItemBlock(item)

						if (!itemBlock) return min
						const content = itemBlock.children[0] as HTMLElement

						if (!content) return min
						const newIndex = Math.ceil(content.offsetHeight / GRID_COL_HEIGHT)

						return max > newIndex ? max : newIndex
					}

					return max
				})
			})

			const flattenArr = ([] as number[]).concat(...minMaxArr)
			const flattenIds = ([] as string[]).concat(...correctIds)
			const promise = animateBlocksHeights(flattenArr, flattenIds)

			Promise.all(promise).then(res)
		})
	}

	const initActivators = () => {
		const { activators } = state

		if (!activators) return
		const { length } = activators

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const activator = activators[i]

			if (!activator) continue
			handleActivateResizeEvents(activator)
		}
	}

	const getRowHeightCondition = (before: number, after: number) => before === after || before < after

	const initResizeEvents = (t = `${selector.item}:not(${selector.disable})`) => {
		const { widths, heights } = state
		const oldSizes = state.sizes

		interact(t)
			.resizable({
				edges: { right: true, bottom: true },
				modifiers: [
					interact.modifiers!.restrictSize({
						min: { width: widths[0], height: heights[0] },
					}),
				],
			})
			.on('resizestart', event => {
				const { target } = event
				if (!event.edges.bottom) return

				if (!target) return
				const h = target.getAttribute(selector.height)
				const targetId = target.getAttribute(selector.itemId)

				if (typeof h === 'undefined' || !targetId) return
				if (parseFloat(h) === 0) {
					target.setAttribute(selector.height, String(AUTO_ONRESIZE_HEIGHT_INDEX))
					state.sizes[targetId].h = AUTO_ONRESIZE_HEIGHT_INDEX
				}
			})
			.on('resizemove', event => {
				const { target } = event
				const { width, height } = event.rect

				if (!target) return
				target.style.flex = getPercents(target.parentNode.offsetWidth, width)
				target.style.maxWidth = getPercents(target.parentNode.offsetWidth, width)
				target.style.height = `${height}px`

				if (target.offsetTop + target.offsetHeight + SCROLL_LAST_ROW_THRESHOLD > document.body.scrollHeight) {
					document.documentElement.scrollTop = target.offsetTop + target.offsetHeight
				}
			})
			.on('resizeend', event => {
				const { target } = event
				const { width, height } = event.rect
				const h = target.getAttribute(selector.height)
				const targetId = target.getAttribute(selector.itemId)
				const parentId = target.getAttribute(selector.parentId)
				const actualHeightIndex = state.sizes[targetId].h
				const shouldUpdateHeight = parseFloat(h) !== 0

				if (!targetId) {
					console.error(`Missing ${selector.itemId} on the target!`)
				}

				makeLoading()
				useAnimation({
					el: target,
					from: {
						maxWidth: getPercents(target.parentNode.offsetWidth, width),
						flex: getPercents(target.parentNode.offsetWidth, width),
						height: `${height}px`,
					},
					to: {
						maxWidth: getNearestWidth(getPercentsNumber(target.parentNode.offsetWidth, width), targetId),
						flex: getNearestWidth(getPercentsNumber(target.parentNode.offsetWidth, width), targetId),
						height: shouldUpdateHeight ? getNearestHeight(height, targetId) : height,
					},
					duration: ANIMATION_TIME,
					onComplete: () => {
						const { sizes } = state
						if (sizes[targetId] && sizes[targetId].w) {
							target.setAttribute(selector.width, String(sizes[targetId].w))
						}

						if (sizes[targetId] && typeof sizes[targetId].h !== 'undefined') {
							target.setAttribute(selector.height, String(sizes[targetId].h))
							if (target.getAttribute(selector.autoHeight)) {
								target.setAttribute(selector.autoHeight, String(sizes[targetId].h))
							}

							if (typeof actualHeightIndex === 'undefined') return
							if (shouldUpdateHeight) {
								setRowHeights(
									targetId,
									getRowHeightCondition(actualHeightIndex, sizes[targetId].h!),
									parentId,
									oldSizes,
								)
							}
						}
						if (!shouldUpdateHeight && state.onResizeComplete) {
							autoCorrectHeights(oldSizes, parentId).then(state.onResizeComplete)
						}
						initSizes()
					},
				})
			})
	}

	const destroyResizeEvent = () => {
		interact(selector.item).unset()
	}

	const autoHeights = async (sizes: any) => {
		const { onAutoHeight } = state
		const oldKeys = Object.keys(sizes)
		const keys = Object.keys(state.sizes)
		const newKeys = keys.filter(item => oldKeys.indexOf(item) < 0)

		for (let i = 0, { length } = newKeys; i < length; i++) {
			const key = newKeys[i]
			const size = state.sizes[key]

			if (!size || (size && !size.h)) continue
			await setRowHeights(key, getRowHeightCondition(size.h!, size.h!), size.parent || '')
		}

		initSizes()
		if (onAutoHeight && newKeys.length > 0) onAutoHeight(getSizesByKeys(newKeys))
	}

	const setAutoHeightsComplete = (autoHeightsComplete: boolean) => setState({ autoHeightsComplete })

	const initAutomaticHeight = (parentId?: string) => {
		return new Promise(res => {
			if (state.autoHeightsComplete) {
				res()
				return
			}
			const { sizes, heights } = state

			if (!sizes) {
				res()
				return
			}
			const filtteredSizes = parentId ? makeParentsSizes(sizes) : makeFilteredSizes(sizes, '')
			const parents = makeParents(sizes)
			const keys = Object.keys(filtteredSizes)
			const values = Object.values(filtteredSizes)
			const sizesLength = keys.length

			if (sizesLength === 0) {
				res()
				return
			}
			const arr: number[][] = []
			const ids: string[][] = []
			let index = 0
			let arrIndex = 0
			let totalW = 0

			while (index < sizesLength) {
				const key = keys[index]
				const { w, h } = values[index]

				if (!w) continue

				totalW += w

				if (totalW <= GRID_COLS) {
					if (!arr[arrIndex]) arr[arrIndex] = []
					if (!ids[arrIndex]) ids[arrIndex] = []
					arr[arrIndex].push(typeof h === 'undefined' ? 1 : h)
					ids[arrIndex].push(key)
				}

				if (totalW > GRID_COLS) {
					totalW = 0
					arrIndex++
					index--
				}

				index++
			}

			const autoZeroArr = arr.map((subArr, key) => {
				return subArr.map((value, subKey) => {
					if (value > 0) {
						return Math.max(...subArr)
					}
					const itemId = ids[key][subKey]
					const item = resizerSelectors.getItemById(itemId)

					if (!item) return value
					const content = resizerSelectors.getItemBlockContent(item)

					if (!content) return value
					return Math.ceil(content.offsetHeight / GRID_COL_HEIGHT)
				})
			})

			const minMaxArr = autoZeroArr.map(subArr => {
				return subArr.map(() => {
					return Math.max(...subArr)
				})
			})

			const flattenArr = ([] as number[]).concat(...minMaxArr)
			const flattenIds = ([] as string[]).concat(...ids)
			const arrLength = flattenArr.length
			const idsLength = flattenIds.length

			if (arrLength !== idsLength) return
			const promise = [] as Promise<any>[]

			for (let i = 0; i < idsLength; i++) {
				const itemId = flattenIds[i]
				const newHeight = flattenArr[i]

				if (!itemId) continue
				const item = resizerSelectors.getItemById(itemId)

				if (!item) continue
				item.setAttribute(selector.autoHeight, String(newHeight))
				promise.push(
					new Promise<any>(resolve => {
						setTimeout(() => {
							useAnimation({
								el: item,
								from: { height: `${item.offsetHeight}px` },
								to: { height: `${heights[newHeight - 1]}px` },
								duration: AUTO_ANIMATION_TIME,
								onComplete: resolve,
							})
						}, 20)
					}),
				)
			}

			if (!parentId || parentId.length === 0) {
				for (let i = 0, { length } = parents; i < length; i++) {
					promise.push(initAutomaticHeight(parents[i]))
				}
			}

			Promise.all(promise).then(res)
		})
	}

	const getActualSizesFromDOM = () => {
		const sizes: Sizes = {}
		const items = resizerSelectors.getItems()

		if (!items) return sizes
		const { length } = items

		if (length === 0) return sizes

		for (let i = 0; i < length; i++) {
			const item = items[i]
			const id = item.getAttribute(selector.itemId)
			const parent = item.getAttribute(selector.parentId)
			const w = item.getAttribute(selector.width)
			const h = item.getAttribute(selector.height)

			if (!id) continue
			if (w) {
				sizes[id] = { w: parseFloat(w) }
			}

			if (h) {
				sizes[id] = { ...sizes[id], h: parseFloat(h) }
			}

			if (parent) {
				sizes[id] = { ...sizes[id], parent }
			}
		}

		return sizes
	}

	const activateWrapperChildren = (activate: boolean) => {
		const { items } = state
		if (!items) return
		for (let i = 0, { length } = items; i < length; i++) {
			const item = items[i]
			if (!cn.hasClass(item, selector.hasWrapper)) continue
			if (activate) {
				cn.removeClass(item, modifier.disabled)
			} else {
				cn.addClass(item, modifier.disabled)
			}
		}
	}

	const initControlKey = () => {
		const keyDown = useEvent<KeyboardEvent>(document, 'keydown')
		const keyUp = useEvent<KeyboardEvent>(document, 'keyup')
		let pressed = false

		keyDown.register(({ e }) => {
			if (!state.active) return
			if (e.key === 'Control' || e.key === 'Meta' || !pressed) {
				pressed = true
				activateWrapperChildren(true)
			}
		})

		keyUp.register(({ e }) => {
			if (!state.active) return
			if (e.key === 'Control' || e.key === 'Meta' || pressed) {
				pressed = false
				activateWrapperChildren(false)
			}
		})
	}

	const initSizes = () => {
		setState({ sizes: getActualSizesFromDOM() })
	}

	const initSortable = () => {
		const { wrap, onAdd, onSortChange } = state
		const { length } = wrap
		const sortables = [] as Sortable[]

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const sortable = new Sortable(wrap[i], {
				animation: 200,
				group: 'resizer',
				onUpdate: event => {
					initSizes()
					setAutoHeightsComplete(false)
					initAutomaticHeight().then(() => {
						if (onSortChange) onSortChange(event)
					})
				},
				onAdd: e => {
					if (onAdd) onAdd(e)
				},
			})
			sortables.push(sortable)
		}

		setState({ sortables })
	}

	const destroySortable = () => {
		const { sortables } = state

		if (!sortables) return
		const { length } = sortables

		if (length === 0) return
		for (let i = 0; i < length; i++) {
			const sortable = sortables[i]
			sortable.destroy()
		}
	}

	const getSizesByKeys = (keys: string[]) => {
		if (!keys) return {}
		const { sizes } = state
		const { length } = keys
		const result: Sizes = {}

		if (length === 0) return {}
		for (let i = 0; i < length; i++) {
			const key = keys[i]

			if (!key || !sizes[key]) continue
			result[key] = sizes[key]
		}

		return result
	}

	const getSizes = () => state.sizes

	const getSortables = () => state.sortables

	const setSortables = (newSortables: Sortable[]) => {
		setState({ sortables: [...state.sortables, ...newSortables] })
	}

	const automaticHeight = () => {
		return new Promise(res => {
			initAutomaticHeight().then(() => {
				setAutoHeightsComplete(true)
				res()
			})
		})
	}

	const getActive = () => state.active

	const fixHeight = () => {
		setAutoHeightsComplete(false)
	}

	const init = (props: InitProps) => {
		destroyResizeEvent()
		setState({
			...resizerSelectors.getResizerSelectors(),
			...props,
		})

		const { sizes } = state

		initSizes()
		initActivators()
		initControlKey()

		if (props.onInit) props.onInit()

		if (state.active) {
			destroyItemCover()
			autoHeights(sizes)
			reinitActive()
		}
	}

	return {
		fixHeight,
		init,
		automaticHeight,
		getSizes,
		getActive,
		getSortables,
		setSortables,
		initSizes,
		setRowHeights,
		reinitActive,
		makeLoading,
		destroyLoading,
		destroyResizerLoading,
	}
}
