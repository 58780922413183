import React from 'react'

type Props = {
	fulltext: string
	handleFulltextChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const INPUT_ID = 'fulltext-search'

const FulltextFilterInput = ({ fulltext, handleFulltextChange }: Props) => {
	return (
		<div className="input-field">
			<input onChange={handleFulltextChange} id={INPUT_ID} className="validate" type="text" value={fulltext} />
			<label htmlFor={INPUT_ID}>Search</label>
		</div>
	)
}

export default React.memo(FulltextFilterInput)
