// import 'preact/debug' // Unsupported with new react-devtools
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { makeStore } from 'store'
import { FulltextFilter } from './FulltextFilter'

const store = makeStore()

const Fulltext = (() => {
	const init = () => {
		const root = document.getElementById('fulltext-filter')

		if (!root) return
		const endpoint = root.getAttribute('data-fulltext-endpoint') || ''
		const ident = root.getAttribute('data-filter-ident') || ''
		const initialState = root.getAttribute('data-fulltext-initial-state') || ''

		ReactDOM.render(
			<Provider store={store}>
				<FulltextFilter endpoint={endpoint} ident={ident} initialState={initialState} />
			</Provider>,
			root,
		)
	}

	return {
		init,
	}
})()

export default Fulltext
